import React from 'react'
import Layout from '../../components/layout'

import { Link } from 'gatsby'

const RuThanksPage = () => (
  <Layout>
    <p>Спасибо за бронирование первоначальной консультации.</p>
    <p>Вскоре вы получите электронное письмо с подтверждением.</p>
    <p>Если у вас есть какие-либо вопросы, вы можете их задать, отправив письмо на hello@duraj.space.</p>
    <p>Вернуться на <Link to='/ru'>главную страницу</Link>.</p>
  </Layout>
)

export default RuThanksPage
